var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.load),expression:"load"}],staticClass:"container",attrs:{"infinite-scroll-delay":300,"infinite-scroll-immediate":false,"infinite-scroll-distance":220}},[_c('div',{staticClass:"edit"},[_c('div',{staticClass:"inner"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.$store.state.userStore.userInfo.user_avatar
            ? _vm.$store.state.userStore.userInfo.user_avatar
            : 'https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png'}}),_c('quill-editor',{staticClass:"quill-editor-ref",attrs:{"options":_vm.editorOption},model:{value:(_vm.formData.card_content),callback:function ($$v) {_vm.$set(_vm.formData, "card_content", $$v)},expression:"formData.card_content"}})],1),_c('div',{staticClass:"btn-group"},[_c('el-upload',{ref:"upload",attrs:{"action":"http://upload.qiniup.com/","accept":"image/*,video/*","list-type":"picture-card","limit":9,"data":_vm.uploadData,"file-list":_vm.fileList,"auto-upload":true,"on-success":_vm.uploadSuccess,"before-upload":_vm.beforeUpload},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i',{staticClass:"el-icon-plus"})]},proxy:true},{key:"file",fn:function(ref){
            var file = ref.file;
return [(_vm.isImage(file))?_c('img',{staticClass:"el-upload-list__item-thumbnail",staticStyle:{"object-fit":"cover"},attrs:{"src":file.url}}):_vm._e(),(_vm.isVideo(file))?_c('div',{staticStyle:{"width":"100%","height":"100%","position":"relative；"}},[_c('video',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":file.url,"autoplay":"","muted":""},domProps:{"muted":true}})]):_vm._e(),_c('span',{staticClass:"el-upload-list__item-actions"},[_c('span',{staticClass:"el-upload-list__item-preview",on:{"click":function (e) { return _vm.handlePictureCardPreview(e, _vm.ile); }}},[_c('i',{staticClass:"el-icon-zoom-in"})]),_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(file)}}},[_c('i',{staticClass:"el-icon-delete"})])])]}}])}),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"before-close":_vm.beforeClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogImageUrl)?_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl}}):_vm._e(),(_vm.dialogVideoUrl)?_c('video',{staticStyle:{"width":"100%"},attrs:{"autoplay":"","controls":"","muted":"","src":_vm.dialogVideoUrl},domProps:{"muted":true}}):_vm._e()])],1),_c('div',{staticClass:"btn-group"},[(!_vm.formData.tag_id[0])?_c('el-popover',{attrs:{"placement":"bottom","width":"400","trigger":"click"}},[_vm._l((_vm.tagList),function(ref){
            var tag_id = ref.tag_id;
            var tag_name = ref.tag_name;
return _c('el-button',{key:tag_id,attrs:{"disable":_vm.formData.tag_id[0] && tag_id !== _vm.formData.tag_id[0],"size":"mini","round":""},on:{"click":function($event){return _vm.tagClick(tag_id)}}},[_vm._v(_vm._s(tag_name))])}),_c('el-tag',{attrs:{"slot":"reference","effect":"dark"},slot:"reference"},[_vm._v(" 添加标签 "),_c('i',{staticClass:"el-icon-collection-tag"})])],2):_c('el-tag',{attrs:{"closable":"","effect":"plain"},on:{"close":function($event){_vm.formData.tag_id = []}}},[_vm._v(" # "+_vm._s(_vm.tagList.find(function (e) { return e.tag_id === _vm.formData.tag_id[0]; })["tag_name"])+" ")]),_c('a',{staticClass:"tweet",class:{ disable: _vm.disable },attrs:{"href":"javascript:void(0)"},on:{"click":_vm.doSubmit}},[_vm._v(" Tweet ")])],1)]),_c('CardItem',{attrs:{"data-list":_vm.dataList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }