<template>
  <div
    class="container"
    :infinite-scroll-delay="300"
    :infinite-scroll-immediate="false"
    :infinite-scroll-distance="220"
    v-infinite-scroll="load"
  >
    <!-- 当前登录:{{ $store.state.userStore.userInfo.user_name }} -->
    <div class="edit">
      <div class="inner">
        <img
          class="avatar"
          :src="
            $store.state.userStore.userInfo.user_avatar
              ? $store.state.userStore.userInfo.user_avatar
              : 'https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png'
          "
        />
        <quill-editor
          class="quill-editor-ref"
          v-model="formData.card_content"
          :options="editorOption"
        />
      </div>
      <div class="btn-group">
        <el-upload
          action="http://upload.qiniup.com/"
          accept="image/*,video/*"
          list-type="picture-card"
          :limit="9"
          :data="uploadData"
          :file-list="fileList"
          :auto-upload="true"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
          ref="upload"
        >
          <template #default>
            <i class="el-icon-plus"></i>
          </template>
          <template #file="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              style="object-fit: cover"
              :src="file.url"
              v-if="isImage(file)"
            />

            <div
              style="width: 100%; height: 100%; position: relative；"
              v-if="isVideo(file)"
            >
              <video
                style="width: 100%; height: 100%; object-fit: cover"
                :src="file.url"
                autoplay
                muted
              ></video>
            </div>

            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="(e) => handlePictureCardPreview(e, ile)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </template>
        </el-upload>

        <!-- 预览 -->
        <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose">
          <img width="100%" :src="dialogImageUrl" v-if="dialogImageUrl" />
          <video
            style="width: 100%"
            autoplay
            controls
            muted
            v-if="dialogVideoUrl"
            :src="dialogVideoUrl"
          ></video>
        </el-dialog>
      </div>

      <div class="btn-group">
        <el-popover
          v-if="!formData.tag_id[0]"
          placement="bottom"
          width="400"
          trigger="click"
        >
          <el-button
            @click="tagClick(tag_id)"
            v-for="{ tag_id, tag_name } in tagList"
            :key="tag_id"
            :disable="formData.tag_id[0] && tag_id !== formData.tag_id[0]"
            size="mini"
            round
            >{{ tag_name }}</el-button
          >

          <el-tag slot="reference" effect="dark">
            添加标签 <i class="el-icon-collection-tag"></i>
          </el-tag>
        </el-popover>

        <el-tag v-else closable effect="plain" @close="formData.tag_id = []">
          #
          {{ tagList.find((e) => e.tag_id === formData.tag_id[0])["tag_name"] }}
        </el-tag>
        <a
          href="javascript:void(0)"
          class="tweet"
          :class="{ disable }"
          @click="doSubmit"
        >
          Tweet
        </a>
      </div>
    </div>
    <CardItem :data-list="dataList" />
  </div>
</template>

<script>
import crypto from "crypto";
import { cardAdd } from "@/api/card";
import { recommedList } from "@/api/recommend";
import { qiniuToken } from "@/api/account";
import CardItem from "@/components/CardItem";
import { tagList } from "@/api/tag";
export default {
  name: "home",
  data() {
    return {
      editorOption: {
        placeholder: "What's happening?",
        theme: "bubble",
        bounds: ".quill-editor-ref",
        modules: {
          // 关闭工具栏
          toolbar: false,
          clipboard: {
            // 自定义匹配方法 过滤掉复制图片和文本样式
            matchers: [
              [
                Node.ELEMENT_NODE,
                (node, Delta) => {
                  let ops = [];
                  Delta.ops.forEach((op) => {
                    if (op.insert && typeof op.insert === "string") {
                      // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
                      ops.push({
                        insert: op.insert,
                      });
                    } else {
                      // this.$message({
                      //   message: "不允许粘贴图片,请手动上传",
                      //   type: "warning",
                      // });
                    }
                  });
                  Delta.ops = ops;
                  return Delta;
                },
              ],
            ],
          },
          // 关闭语法高亮
          syntax: false,
        },
      },
      uploadData: {
        fname: "",
        key: "",
        token: "",
      },
      // 反显用的 接口返回的数据不是这样的 需要自己改成这种结构
      fileList: [
        // {
        //   type: "image",
        //   name: "11",
        //   url: "http://files.stars-mine.com/99999/2021/9/29/9ee06f2f7487af9b742b19c4a1e08da9.png",
        // },
        // {
        //   type: "video",
        //   name: "22",
        //   url: "http://files.stars-mine.com/99999/2021/9/29/578c3f04b92e8f843b6d03902a6aa1bc.mp4",
        // },
      ],
      formData: {
        card_content: "",
        card_media: [],
        tag_id: [],
      },
      dialogVisible: false,
      dialogVideoUrl: "",
      dialogImageUrl: "",
      dataList: [],
      page: 1,
      isAll: false,
      tagList: [],
    };
  },
  components: {
    CardItem,
  },
  computed: {
    disable() {
      return (
        !this.formData.card_content.length && !this.formData.card_media.length
      );
    },
  },
  created() {
    this.__loadListData();
    this.__loadTagList();
  },
  methods: {
    async doSubmit() {
      if (!this.disable) {
        const { card_media, card_content, tag_id } = { ...this.formData };
        const { code, msg } = await cardAdd({
          card_media: JSON.stringify(card_media),
          card_content,
          tag_id: tag_id[0] ?? 0,
        });

        if (code === 200) {
          this.$message.success("发布成功");
          window.location.reload();
        } else {
          this.$message.success(`发布失败,原因是:${msg}`);
        }
      }
    },
    async __loadListData() {
      const { code, data } = await recommedList({
        page: this.page,
        limit: 5,
      });

      if (code === 200 && data.length > 0) {
        this.dataList = [...this.dataList, ...data];
        this.isAll = false;
      } else {
        this.isAll = true;
      }
    },
    async __loadTagList() {
      const { data } = await tagList({ page: 1, limit: 99999 });
      this.tagList = data;
    },
    /**
     * 上传成功
     * @param {key:string;hash:string} response
     */
    uploadSuccess({ key }, { raw }) {
      const baseUrl = "http://files.stars-mine.com/";
      let type = "";
      if (raw.type.includes("image/")) {
        type = "image";
      } else if (raw.type.includes("video/")) {
        type = "video";
      }
      this.formData.card_media.push({ type, url: baseUrl + key });
    },
    async beforeUpload({ name, type }) {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const str = `${Date.now()}_${name}_${Math.random()}`;
      const md5 = crypto.createHash("md5");
      const fileName = md5.update(str).digest("hex");

      // key即为文件名
      this.uploadData.key = `99999/${year}/${month}/${day}/${fileName}.${type
        .replace("image/", "")
        .replace("video/", "")}`;

      this.uploadData.fname = name;

      const {
        data: { token },
      } = await qiniuToken();

      this.uploadData.token = token;
    },
    isImage(file) {
      return file.type === "image" || file.raw?.type?.includes("image/");
    },
    isVideo(file) {
      return file.type === "video" || file.raw?.type?.includes("video/");
    },
    // 预览层关闭前
    beforeClose(close) {
      this.dialogImageUrl = "";
      this.dialogVideoUrl = "";
      close();
    },
    // 预览图/视频
    handlePictureCardPreview(e, file) {
      e.stopPropagation();
      this.dialogVisible = true;

      if (this.isImage(file)) {
        this.dialogImageUrl = file.url;
      } else if (this.isVideo(file)) {
        this.dialogVideoUrl = file.url;
      }
    },
    // 删除图/视频
    handleRemove(file) {
      const baseUrl = "http://files.stars-mine.com/";
      let url = file.url;

      if (file.response?.key) {
        url = baseUrl + file.response?.key;
      }

      // 处理请求数据
      this.formData.card_media = this.formData.card_media.filter(
        (item) => item.url !== url
      );

      // 处理展示数据
      const $upload = this.$refs.upload;
      $upload.uploadFiles = $upload.uploadFiles.filter((item) => {
        return url !== item.url && !url.includes(item.response?.key);
      });
    },
    // 转换接口返回的媒体资源格式
    transformationFileList(data) {
      data = JSON.parse(data);
      return data.map((e, index) => {
        Reflect.set(e, "name", `${index}_${e.type}`);
        return e;
      });
    },
    // 图片补位
    placeholderCount(card_media) {
      const length = JSON.parse(card_media).length % 3;

      if (length === 0) {
        return 0;
      }

      return 3 - length;
    },
    load() {
      if (!this.isAll) {
        ++this.page;
        this.__loadListData();
      }
    },
    tagClick(id) {
      this.formData.tag_id = [id];
    },
  },
};
</script>

<style lang="scss" scoped>
div.container {
  overflow: hidden;
  .edit {
    padding: 12px 16px;
    border-bottom: 8px solid rgb(239, 243, 244);

    .inner {
      display: flex;

      & > img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 12px;
        border: 1px solid rgba(0, 0, 0, 0.04);
      }

      .quill-editor-ref {
        min-height: 200px;
        max-height: 300px;
        overflow: scroll;
        width: calc(100% - 48px - 12px);
        border-bottom: 1px solid rgb(239, 243, 244);
        overflow: hidden;
      }
    }

    .btn-group {
      margin-left: calc(48px + 12px);
      margin-top: 16px;
      display: flex;
      align-items: center;

      /deep/ .el-tag {
        margin-right: 10px;
        cursor: pointer;
        border-radius: 9999px;
      }

      .tweet {
        display: block;
        width: 76px;
        text-align: center;
        background-color: rgb(29, 155, 240);
        color: #fff;
        border-radius: 9999px;
        margin: 0 0 0 auto;
        line-height: 34px;
        text-decoration: none;
        font-size: 15px;
        font-weight: 700;

        &.disable {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }

  /deep/ .ql-container {
    font-size: 24px;
  }
}
</style>